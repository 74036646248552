<template>
  <NuxtI18nLink
    :to="event.url ?? '/'"
    class="competitor-names"
    data-t="competitor-names"
    :class="classes"
    :prefetch="isMobile"
  >
    <span data-t="competitor-home" class="competitor-name">
      {{ event.home.name }}
    </span>
    <span data-t="competitor-away" class="competitor-name">
      {{ event.away.name }}
    </span>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'

const props = withDefaults(
  defineProps<{
    event: TSportEvent
    compact?: boolean
    size?: 'm' | 's'
    isMobile?: boolean
  }>(),
  {
    size: 'm',
    compact: false,
    isMobile: false,
  },
)

const classes = computed(() => [
  `size-${props.size}`,
  {
    compact: props.compact,
  },
])
</script>

<style scoped>
.competitor-names {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
  text-decoration: none;

  &.compact {
    gap: 0;
  }

  &.size-s {
    font: var(--desktop-text-xs-semibold);
  }

  &.size-m {
    font: var(--desktop-text-sm-medium);
  }

  &:hover {
    color: var(--text-link);
  }
}

.competitor-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
